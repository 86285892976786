import React from "react";

import {
    Col,
    Container,
    Row
  } from "react-bootstrap";
const ShoplazzaFooter = () => {
  return (
    <>
 <Container fluid className="">
          <Row>
            <Col>
            <div class="tw-content-grid" >
  
      <div class="tw-col-span-6 lg:tw-col-span-2 sm:tw-col-span-3">
        
            <div class="tw-overflow-hidden ">
              <p class="tw-m-0 tw-mb-2 tw-text-grey-50">SELL ONLINE</p>
              <ul class="tw-m-0 tw-p-0 tw-list-none">
                
                <li class="tw-mb-2 ">
                  <a class="tw-no-underline tw-text-black" href="https://shoplazza.com/pages/theme">Theme</a>
                </li>
                
                <li class="tw-mb-2 ">
                  <a class="tw-no-underline tw-text-black" href="https://shoplazza.com/pages/product-sourcing">Product Sourcing</a>
                </li>
                
                <li class="tw-mb-2 ">
                  <a class="tw-no-underline tw-text-black" href="https://shoplazza.com/pages/shopping-cart">Shopping Cart</a>
                </li>
                
                <li class="tw-mb-2 ">
                  <a class="tw-no-underline tw-text-black" href="https://shoplazza.com/pages/checkout">Checkout</a>
                </li>
                
                <li class="tw-mb-2 ">
                  <a class="tw-no-underline tw-text-black" href="https://shoplazza.com/pages/pricing">Pricing</a>
                </li>
                
                <li class="tw-mb-2 ">
                  <a class="tw-no-underline tw-text-black" href="https://shoplazza.com/pages/staff-account">Staff Account</a>
                </li>
                
                <li class="tw-mb-2 ">
                  <a class="tw-no-underline tw-text-black" href="https://shoplazza.com/pages/inventory">Inventory</a>
                </li>
                
                <li class="tw-mb-2 tw-mb-0">
                  <a class="tw-no-underline tw-text-black" href="https://shoplazza.com/pages/payment">Global Payments</a>
                </li>
                
              </ul>
              
            </div>
          
        
          
            
            
            
            <div class="tw-overflow-hidden tw-mt-6 lg:tw-hidden">
              
              <p class="tw-m-0 tw-mb-2 tw-text-grey-50">MARKETING</p>
              
    
              
              
              <ul class="tw-m-0 tw-p-0 tw-list-none">
                
                <li class="tw-mb-2 ">
                  <a class="tw-no-underline tw-text-black" href="https://shoplazza.com/pages/facebook-instagram">Meta</a>
                </li>
                
                <li class="tw-mb-2 ">
                  <a class="tw-no-underline tw-text-black" href="https://shoplazza.com/pages/tiktok">TikTok</a>
                </li>
                
                <li class="tw-mb-2 ">
                  <a class="tw-no-underline tw-text-black" href="https://shoplazza.com/pages/google">Google</a>
                </li>
                
                <li class="tw-mb-2 ">
                  <a class="tw-no-underline tw-text-black" href="https://shoplazza.com/pages/email-marketing">Email Marketing</a>
                </li>
                
                <li class="tw-mb-2 ">
                  <a class="tw-no-underline tw-text-black" href="https://shoplazza.com/pages/affiliate-marketing">Affiliate Marketing</a>
                </li>
                
                <li class="tw-mb-2 tw-mb-0">
                  <a class="tw-no-underline tw-text-black" href="https://shoplazza.com/pages/seo">SEO</a>
                </li>
                
              </ul>
              
            </div>
          
        
      </div>
    
      
      <div class="tw-flex tw-flex-col tw-col-span-6 lg:tw-col-span-6 lg:tw-grid lg:tw-grid-flow-col lg:tw-grid-cols-3 lg:tw-grid-rows-2-auto lg:tw-gap-6 sm:tw-col-span-3">
        
          
            
            
            
            <div class="tw-overflow-hidden tw-mb-6 lg:tw-mb-0 tw-hidden lg:tw-block ">
              
              <p class="tw-m-0 tw-mb-2 tw-text-grey-50">MARKETING</p>
              
    
              
              
              <ul class="tw-m-0 tw-p-0 tw-list-none">
                
                <li class="tw-mb-2 ">
                  <a class="tw-text-black tw-no-underline" href="https://shoplazza.com/pages/facebook-instagram">Meta</a>
                </li>
                
                <li class="tw-mb-2 ">
                  <a class="tw-text-black tw-no-underline" href="https://shoplazza.com/pages/tiktok">TikTok</a>
                </li>
                
                <li class="tw-mb-2 ">
                  <a class="tw-text-black tw-no-underline" href="https://shoplazza.com/pages/google">Google</a>
                </li>
                
                <li class="tw-mb-2 ">
                  <a class="tw-text-black tw-no-underline" href="https://shoplazza.com/pages/email-marketing">Email Marketing</a>
                </li>
                
                <li class="tw-mb-2 ">
                  <a class="tw-text-black tw-no-underline" href="https://shoplazza.com/pages/affiliate-marketing">Affiliate Marketing</a>
                </li>
                
                <li class="tw-mb-2 tw-mb-0">
                  <a class="tw-text-black tw-no-underline" href="https://shoplazza.com/pages/seo">SEO</a>
                </li>
                
              </ul>
              
            </div>
          
        
          
            
            
            
            <div class="tw-overflow-hidden tw-mb-6 lg:tw-mb-0  ">
              
              <p class="tw-m-0 tw-mb-2 tw-text-grey-50">SOLUTIONS</p>
              
    
              
              
              <ul class="tw-m-0 tw-p-0 tw-list-none">
                
                <li class="tw-mb-2 tw-mb-0">
                  <a class="tw-text-black tw-no-underline" href="https://shoplazza.com/pages/direct-to-consumer-intro">DTC Brands</a>
                </li>
                
              </ul>
              
            </div>
          
        
          
            
            
            
            <div class="tw-overflow-hidden tw-mb-6 lg:tw-mb-0  ">
              
              <p class="tw-m-0 tw-mb-2 tw-text-grey-50">RESOURCE</p>
              
    
              
              
              <ul class="tw-m-0 tw-p-0 tw-list-none">
                
                <li class="tw-mb-2 ">
                  <a class="tw-text-black tw-no-underline" href="https://community.shoplazza.com/">Community</a>
                </li>
                
                <li class="tw-mb-2 ">
                  <a class="tw-text-black tw-no-underline" href="https://shoplazza.com/pages/blogs">Blog</a>
                </li>
                
                <li class="tw-mb-2 ">
                  <a class="tw-text-black tw-no-underline" href="https://helpcenter.shoplazza.com/hc/en-us">Help Center</a>
                </li>
                
                <li class="tw-mb-2 ">
                  <a class="tw-text-black tw-no-underline" href="https://shoplazza.app/?locale=en-US">App Store</a>
                </li>
                
                <li class="tw-mb-2 tw-mb-0">
                  <a class="tw-text-black tw-no-underline" href="https://haloo.shoplazza.com/">Trademark Tool</a>
                </li>
                
              </ul>
              
            </div>
          
        
          
            
            
            
            <div class="tw-overflow-hidden tw-mb-6 lg:tw-mb-0  ">
              
              <p class="tw-m-0 tw-mb-2 tw-text-grey-50">PARTNERS</p>
              
    
              
              
              <ul class="tw-m-0 tw-p-0 tw-list-none">
                
                <li class="tw-mb-2 ">
                  <a class="tw-text-black tw-no-underline" href="https://partners.shoplazza.com/?locale=en-US">Partner Centre</a>
                </li>
                
                <li class="tw-mb-2 ">
                  <a class="tw-text-black tw-no-underline" href="https://www.shoplazza.dev/">Developer Centre</a>
                </li>
                
                <li class="tw-mb-2 tw-mb-0">
                  <a class="tw-text-black tw-no-underline" href="https://www.shoplazza.com/pages/brand-resource">Brand Resource</a>
                </li>
                
              </ul>
              
            </div>
          
        
          
            
            
            
            <div class="tw-overflow-hidden tw-mb-6 lg:tw-mb-0  tw-mb-0">
              
              <p class="tw-m-0 tw-mb-2 tw-text-grey-50">SHOPLAZZA</p>
              
    
              
              
              <ul class="tw-m-0 tw-p-0 tw-list-none">
                
                <li class="tw-mb-2 ">
                  <a class="tw-text-black tw-no-underline" href="https://shoplazza.com/pages/about-us">About Us</a>
                </li>
                
                <li class="tw-mb-2 ">
                  <a class="tw-text-black tw-no-underline" href="https://app.mokahr.com/apply/shoplazza/27045#/">Career</a>
                </li>
                
                <li class="tw-mb-2 ">
                  <a class="tw-text-black tw-no-underline" href="https://shoplazza.com/pages/contact-us">Contact Us</a>
                </li>
                
                <li class="tw-mb-2 ">
                  <a class="tw-text-black tw-no-underline" href="https://shoplazza.com/pages/feedback-support">Support &amp; Complaint</a>
                </li>
                
                <li class="tw-mb-2 tw-mb-0">
                  <a class="tw-text-black tw-no-underline" href="https://www.shoplazzastatus.com">System Status</a>
                </li>
                
              </ul>
              
            </div>
          
        
      </div>
    
      
      <div class="tw-col-span-12 lg:tw-col-span-4 sm:tw-col-span-6">

            <div>
              <p class="tw-m-0 tw-text-grey-50">FOLLOW US</p>
              <ul class="tw-flex tw-flex-wrap tw-mt-3 tw-mb-6 tw-p-0 tw-list-none">
                
                  <li class="tw-mr-4 tw-text-0"><a class="tw-inline-block tw-text-0  tw-text-red" href="https://www.facebook.com/Shoplazza/" rel="nofollow" target="_blank" data-track-href="https://www.facebook.com/Shoplazza/" data-track-event="jumpAddress"><svg class="tw-text-logo-color" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#FF0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M15.75 8.25H14.25C13.6533 8.25 13.081 8.48705 12.659 8.90901C12.2371 9.33097 12 9.90326 12 10.5V21" stroke="#FF0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M9 13.5H15" stroke="#FF0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
    </svg>
    </a></li>
                
                
                  <li class="tw-mr-4 tw-text-0"><a class="tw-inline-block tw-text-0  tw-text-red" href="https://www.linkedin.com/company/shoplazza/" rel="nofollow" target="_blank" data-track-href="https://www.linkedin.com/company/shoplazza/" data-track-event="jumpAddress"><svg class="tw-text-logo-color" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.875 3.375H4.125C3.71079 3.375 3.375 3.71079 3.375 4.125V19.875C3.375 20.2892 3.71079 20.625 4.125 20.625H19.875C20.2892 20.625 20.625 20.2892 20.625 19.875V4.125C20.625 3.71079 20.2892 3.375 19.875 3.375Z" stroke="#FF0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M11.25 10.5V16.5" stroke="#FF0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M8.25 10.5V16.5" stroke="#FF0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M11.25 13.125C11.25 12.4288 11.5266 11.7611 12.0188 11.2688C12.5111 10.7766 13.1788 10.5 13.875 10.5C14.5712 10.5 15.2389 10.7766 15.7312 11.2688C16.2234 11.7611 16.5 12.4288 16.5 13.125V16.5" stroke="#FF0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M8.25 8.625C8.87132 8.625 9.375 8.12132 9.375 7.5C9.375 6.87868 8.87132 6.375 8.25 6.375C7.62868 6.375 7.125 6.87868 7.125 7.5C7.125 8.12132 7.62868 8.625 8.25 8.625Z" fill="#FF0000"></path>
    </svg>
    </a></li>
                
                
                <li class="tw-mr-4 tw-text-0"><a class="tw-inline-block tw-text-0  tw-text-red" href="https://twitter.com/shoplazzacom" rel="nofollow" target="_blank" data-track-href="https://twitter.com/shoplazzacom" data-track-event="jumpAddress"><svg class="tw-text-logo-color" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0005 8.25027C12.0006 7.38867 12.2973 6.55337 12.8408 5.8848C13.3843 5.21624 14.1414 4.75517 14.9848 4.57913C15.8282 4.40309 16.7065 4.5228 17.4721 4.91814C18.2376 5.31348 18.8437 5.96034 19.1885 6.74997L22.5005 6.75L19.4759 9.77461C19.279 12.8168 17.9316 15.67 15.7074 17.7548C13.4833 19.8397 10.549 20.9999 7.50051 21C4.50051 21 3.75051 19.875 3.75051 19.875C3.75051 19.875 6.75051 18.75 8.25051 16.5C8.25051 16.5 2.25051 13.5 3.75051 5.25C3.75051 5.25 7.50051 9 11.9992 9.75L12.0005 8.25027Z" stroke="#FF0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
    </svg>
    </a></li>
                
                
                
                  <li class="tw-mr-4 tw-text-0"><a class="tw-inline-block tw-text-0  tw-text-red" href="https://www.instagram.com/shoplazza.official/" rel="nofollow" target="_blank" data-track-href="https://www.instagram.com/shoplazza.official/" data-track-event="jumpAddress"><svg class="tw-text-logo-color" width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.14 4.75a3.39 3.39 0 0 0-3.39 3.39v7.82a3.39 3.39 0 0 0 3.39 3.39h7.82a3.39 3.39 0 0 0 3.39-3.39V8.14a3.39 3.39 0 0 0-3.39-3.39H8.14zM3 8.14A5.14 5.14 0 0 1 8.14 3h7.82a5.14 5.14 0 0 1 5.14 5.14v7.82a5.14 5.14 0 0 1-5.14 5.14H8.14A5.14 5.14 0 0 1 3 15.96V8.14zm9.05 1.23a2.68 2.68 0 1 0 0 5.36 2.68 2.68 0 0 0 0-5.36zm-4.43 2.68a4.43 4.43 0 1 1 8.86 0 4.43 4.43 0 0 1-8.86 0zm10.118-4.62a1.066 1.066 0 1 1-2.133 0 1.066 1.066 0 0 1 2.133 0z" fill="currentColor"></path></svg>
    </a></li>
                
                
                
                
                
                  <li class="tw-mr-4 tw-text-0"><a class="tw-inline-block tw-text-0  tw-text-red" href="https://www.youtube.com/c/Shoplazza" rel="nofollow" target="_blank" data-track-href="https://www.youtube.com/c/Shoplazza" data-track-event="jumpAddress"><svg class="tw-text-logo-color" width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.986 3.614h.074l.179.001a40.821 40.821 0 0 1 2.832.138c1.71.145 3.904.446 5.601 1.094l.014.005a2.432 2.432 0 0 1 1.45 1.647l.001.004c.238.93.535 2.64.535 5.497 0 2.858-.297 4.567-.535 5.497v.004a2.432 2.432 0 0 1-1.451 1.646l-.014.006c-1.697.648-3.89.949-5.601 1.093a40.841 40.841 0 0 1-3.01.14h-.123c-.041 0-.101 0-.178-.002a40.846 40.846 0 0 1-2.832-.138c-1.711-.144-3.905-.445-5.602-1.093l-.014-.005a2.433 2.433 0 0 1-1.45-1.647v-.004c-.239-.93-.536-2.64-.536-5.497 0-2.858.297-4.567.535-5.497l.001-.004a2.432 2.432 0 0 1 1.45-1.647l.014-.005c1.697-.649 3.891-.95 5.601-1.093a40.826 40.826 0 0 1 3.011-.14h.048zm.018 14.925h-.225a39.032 39.032 0 0 1-2.697-.132c-1.672-.142-3.646-.426-5.089-.976a.587.587 0 0 1-.344-.393c-.193-.758-.477-2.31-.477-5.038 0-2.727.284-4.28.477-5.038a.586.586 0 0 1 .344-.394c1.443-.55 3.417-.834 5.09-.975a38.99 38.99 0 0 1 2.86-.133H12.055a31.988 31.988 0 0 1 .777.014 38.99 38.99 0 0 1 2.083.12c1.673.14 3.647.425 5.09.974a.587.587 0 0 1 .343.394c.194.758.477 2.31.477 5.038 0 2.727-.283 4.28-.477 5.038a.586.586 0 0 1-.344.393c-1.442.55-3.416.834-5.09.976a39.032 39.032 0 0 1-2.859.132H12.004zm-.993-10.307A.923.923 0 0 0 9.576 9v6a.923.923 0 0 0 1.435.768l4.5-3a.923.923 0 0 0 0-1.536l-4.5-3zm.411 5.043v-2.55L13.335 12l-1.913 1.275z" fill="currentColor"></path></svg>
    </a></li>
                
                
              </ul>
            </div>
            

            <div class="region-switch">
              <div class="tw-mt-6 tw-text-grey-50">LANGUAGE</div>
              <div class="tw-group tw-relative tw-mt-3 tw-w-[190px] tw-h-button tw-px-3 tw-flex tw-justify-between tw-items-center tw-border tw-border-solid tw-cursor-pointer">
                <div>English</div>
                <svg class=" tw-block group-hover:tw-hidden" width="12" height="12" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.33594 8.35156V5.15625H8.63281V3.90625H5.33594V0.710938H4.03906V3.90625H0.742188V5.15625H4.03906V8.35156H5.33594Z" fill="currentColor"></path>
    </svg>
    
                <svg class="tw-hidden group-hover:tw-block" width="12" height="12" viewBox="0 0 10 3" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.03125 2.00781V0.875H0.742188V2.00781H9.03125Z" fill="currentColor"></path>
    </svg>
              </div>
            </div>
        
      </div>

      </div>
            </Col>

          </Row>

        </Container>

    </>
  );
};

export default ShoplazzaFooter;

const EmailValidator = require("email-validator");

/**
 * If the given value is not an array, wrap it in one.
 */
export function arrayWrap(value) {
  return Array.isArray(value) ? value : [value];
}

export function validateEmail(email) {
  return EmailValidator.validate(email);
}

export function isDomainAvailable(domainStatus) {
  return domainStatus?.toUpperCase() === "AVAILABLE";
}

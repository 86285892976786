import React, { createContext, useState, useEffect } from "react";
import Errors from "../errors";
import axios from "../axios";
import { isEmpty, trim } from "lodash";
export const TrademarkSearchContext = createContext(null);

const TrademarkSearchContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState({
    fullName: "",
    trademark: "",
    email: "",
    country: "",
    businessCategories: [],
    type: "",
    alternateSpellings: [],
    trademarkLogo: [],
  });

  const [isSearchRunning, setIsSearchRunning] = useState(false);

  const [touched, setTouched] = useState({
    fullName: false,
    email: false,
    businessCategories: false,
    trademark: false,
    trademarkLogoSrc: false,
  });

  const [results, setResults] = useState(null);
  const [searchError, setSearchError] = useState(null);
  const [validationErrors, setValidationErrors] = useState(new Errors());
  const [view, setView] = useState("info");
  const [query, setQuery] = useState(null);
  const [validated, setValidated] = useState(false);

  const [showTrademarkSelectionError, setShowTrademarkSelectionError] =
    useState(false);

  useEffect(() => {
    if (isEmpty(trim(search.fullName)) || isEmpty(trim(search.type))) {
      setValidated(false);
    } else if (
      (search.type !== "logo" && isEmpty(trim(search.trademark))) ||
      (search.type === "logo" && isEmpty(search.trademarkLogo[0]))
    ) {
      setValidated(false);
    } else if (search.businessCategories.length <= 0) {
      setValidated(false);
    } else {
      setValidated(true);
    }
  }, [search]);

  useEffect(() => {
    if (query) fetchSearchResults(query);
  }, [query]);

  const fetchSearchResults = async (query) => {
    axios
      .get(`/${query}`)
      .then((response) => {
        const json = response.data;
        setResults({
          ...json.results,
          trademarkId: json.trademarkId,
          uuid: json.uuid,
          overallRisk: json.overall_risk,
          country: json.country,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setSearchError("queryError");
      });
  };

  const getVisitorLocation = () => {
    axios
      .get(`/my/location`)
      .then((response) => {
        const country = response.data.country;
        if (country && country.toLowerCase() === "ca") {
          setSearch({ ...search, country: "ca" });
        } else {
          setSearch({ ...search, country: "us" });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getVisitorLocation();
  }, []);
  return (
    <TrademarkSearchContext.Provider
      value={{
        search,
        setSearch,
        loading,
        setLoading,
        results,
        setResults,
        view,
        setView,
        query,
        setQuery,
        searchError,
        setSearchError,
        validationErrors,
        setValidationErrors,
        setTouched,
        touched,
        validated,
        showTrademarkSelectionError,
        setShowTrademarkSelectionError,
        isSearchRunning,
        setIsSearchRunning,
      }}
    >
      {children}
    </TrademarkSearchContext.Provider>
  );
};

export default TrademarkSearchContextProvider;

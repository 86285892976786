export const SUPPORTED_FILE_TYPES = {
  jpg: "image/jpg",
  jpeg: "image/jpeg",
  png: "image/png",
  tiff: "image/tiff",
  bmp: "image/bmp",
  gif: "image/gif",
};

export const MAX_FILE_SIZE = 20000000;

export const OVERALLRISKSCORES = {
  low: "low",
  medium: "low",
  mediumlow: "low",
  mediumhigh: "low",
  high: "high",
};

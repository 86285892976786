import React, { useContext } from "react";
import { TrademarkSearchContext } from "../context/TrademarkSearchContextProvider";
import TrademarkType from "../components/TrademarkType";
import YourInfo from "../components/YourInfo";

const contentful = {
  trademarkType: {
    cards: [
      {
        title: "Name",
        tooltip: {
          label: "See a name example",
          content:
            "A name trademark can be your company or brand name, or it can be the name of a product - one or more words without graphic elements. For example, the name Nike is registered to protect Nike Inc.’s corporate identity as a brand name.",
        },
        description:
          "A name trademark can be your company or brand name, or it can be the name of a product - one or more words without graphic elements.",
      },
      {
        title: "Logo",
        tooltip: {
          label: "See a logo example",
          content:
            "A logo is a type of trademark that is a visual design with or without words. For example, the Nike logo is a graphic registered as a trademark to allow consumers to recognize Nike products (e.g., running shoes, clothing).",
        },
        description:
          "A logo is a type of trademark that is a visual design with or without words.",
      },
      {
        title: "Tagline",
        tooltip: {
          label: "See a tagline example",
          content:
            "A tagline is a phrase or combination of words, without graphic elements. For example, the Nike ‘Just Do It’ tagline is a coined phrase used in a unique way by Nike to advertise its products and services.",
        },
        description:
          "A tagline is a phrase or combination of words, without graphic elements.",
      },
    ],
    title: "Type of Trademark",
    logoType: {
      title: "Logo upload",
      inputs: [
        "Drag your image file here to upload.",
        "Please ensure your image is in JPG, PNG, BMP, GIF or TIF format.",
        {
          label: "Industry",
          placeholder: "Search for your industry",
        },
      ],
    },
    nameType: {
      title: "Name Information",
      inputs: [
        {
          label: "Trademark name",
          placeholder: "Enter your trademark name",
        },
        {
          label: "Dictionary spelling (optional)",
          tooltip:
            "For example: If your trademark is Netflix, you would type the way you will spell it 'Netflix' in the first field, and the dictionary word spelling in the second field 'Net Flicks'.",
          placeholder: "Enter the dictionary spelling of your name",
        },
        {
          label: "Industry",
          placeholder: "Search for your Industry",
        },
      ],
      subtitle:
        "For correct results, it is important to search using the exact spelling of your trademark - the way you want to use it.",
    },
    subtitle: "Choose a type of trademark to search.",
    taglineType: {
      title: "Tagline information",
      inputs: [
        {
          label: "Tagline",
          placeholder: "Enter your tagline to trademark",
        },
        {
          label: "Industry",
          placeholder: "Search for your industry",
        },
      ],
      subtitle:
        "To make sure you get the correct results, it’s important to search using the exact spelling of your trademark— just the way you want to use it. ",
    },
  },
  progressBar: {
    prescreen: ["Your information", "Trademark type", "Business information"],
    prescreenTitle: "Trademark Check",
  },
  yourInfo: {
    title: "Tell us about yourself",
    subtitle:
      "First, enter your contact information so we can send you your results.",
  },
};

const PrescreenView = () => {
  const { view } = useContext(TrademarkSearchContext);
  return (
    <div className="prescreening">
      {view === "info" ? (
        <YourInfo
          contentful={{
            yourInfo: contentful.yourInfo,
            progressBar: contentful.progressBar,
          }}
        />
      ) : (
        view === "trademark-type" && (
          <TrademarkType
            contentful={{
              trademarkType: contentful.trademarkType,
              progressBar: contentful.progressBar,
            }}
          />
        )
      )}
    </div>
  );
};

export default PrescreenView;

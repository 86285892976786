import React, { useContext } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { TrademarkSearchContext } from "../context/TrademarkSearchContextProvider";
import JurisdictionInput from "./JurisdictionInput";
import Typeahead from "./Typeahead";

const Tagline = ({ contentful, touched, setTouched }) => {
  const { search, setSearch } = useContext(TrademarkSearchContext);
  return (
    <Row>
      <Col lg={{ offset: 3, span: 6 }}>
        <JurisdictionInput contentful={contentful} />
        <Form.Group controlId="trademark-tagline">
          <Form.Label>{contentful.inputs[0].label}</Form.Label>
          <Form.Control
            className={touched.trademark ? "input input-error" : "input"}
            onBlur={(e) => {
              e.target.value === "" &&
                setTouched({ ...touched, trademark: true });
            }}
            onChange={(e) => {
              setSearch({ ...search, trademark: e.target.value });
              setTouched({ ...touched, trademark: false });
            }}
            type="text"
            placeholder={contentful.inputs[0].placeholder}
            aria-label={contentful.inputs[0].placeholder}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{contentful.inputs[1].label}</Form.Label>
          <Typeahead
            contentful={{
              placeholder: contentful.inputs[1].placeholder,
            }}
            ariaLabel={contentful.inputs[1].placeholder}
            search={search}
            setSearch={setSearch}
            touched={touched}
            setTouched={setTouched}
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

export default Tagline;

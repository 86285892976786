import React, { useContext } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { TrademarkSearchContext } from "../context/TrademarkSearchContextProvider";
import JurisdictionInput from "./JurisdictionInput";
import Typeahead from "./Typeahead";

const Name = ({ contentful, touched, setTouched, validationErrors }) => {
  const { search, setSearch } = useContext(TrademarkSearchContext);
  return (
    <Row className="prescreening-name pb-4 pb-md-0">
      <Col lg={{ offset: 3, span: 6 }}>
        <JurisdictionInput contentful={contentful} />
        <Form.Group controlId="trademark-name">
          <Form.Label>{contentful.inputs[0].label}</Form.Label>
          <Form.Control
            className={
              touched.trademark || validationErrors?.has("trademark")
                ? "input input-error"
                : "input"
            }
            onBlur={(e) => {
              e.target.value === "" &&
                setTouched({
                  ...touched,
                  trademark: true,
                });
            }}
            onChange={(e) => {
              setSearch({
                ...search,
                trademark: e.target.value,
              });
              setTouched({ ...touched, trademark: false });
            }}
            value={search.trademark || ""}
            type="text"
            placeholder={contentful.inputs[0].placeholder}
            aria-label={contentful.inputs[0].placeholder}
          />
        </Form.Group>

        <Form.Group controlId="alternate-spellings">
          <Form.Label>{contentful.inputs[1].label}</Form.Label>
          <Form.Control
            className={
              touched.alternateSpellingOne ||
              validationErrors?.has("alternate_spellings")
                ? "input input-error"
                : "input"
            }
            onChange={(e) => {
              let alternateSpellingCopy = [...search.alternateSpellings];
              alternateSpellingCopy[0] = e.target.value;
              setSearch({
                ...search,
                alternateSpellings: alternateSpellingCopy,
              });
              setTouched({ ...touched, alternateSpellingOne: false });
            }}
            value={search.alternateSpellings[0] || ""}
            type="text"
            placeholder={contentful.inputs[1].placeholder}
            aria-label={contentful.inputs[1].placeholder}
          />
        </Form.Group>
        <Form.Group controlId="industries">
          <Form.Label>{contentful.inputs[2].label}</Form.Label>
          <Typeahead
            contentful={{
              placeholder: contentful.inputs[2].placeholder,
            }}
            ariaLabel={contentful.inputs[2].placeholder}
            search={search}
            setSearch={setSearch}
            touched={touched}
            setTouched={setTouched}
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

export default Name;

import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import uploadIcon from "../assets/icons/upload-circle-icon.svg";
import { MAX_FILE_SIZE, SUPPORTED_FILE_TYPES } from "../constants";
import { TrademarkSearchContext } from "../context/TrademarkSearchContextProvider";
import { isEmpty } from "lodash";
import TIFF_ICON from "../assets/icons/tiff-icon.png";
import Typeahead from "./Typeahead";
import JurisdictionInput from "./JurisdictionInput";

const Logo = ({ contentful }) => {
  const { search, setSearch, touched, setTouched } = useContext(
    TrademarkSearchContext
  );
  const [uploaded, setUploaded] = useState(false);

  const upload = async (e) => {
    try {
      let curFile = e.target.files[0];
      const fileType = curFile.type;
      const fileExt = fileType.substring(fileType.lastIndexOf("/") + 1);
      const fileSizeInMB = Math.ceil(MAX_FILE_SIZE / 1000000);

      if (curFile.size > MAX_FILE_SIZE) {
        console.log(`File is too large - ${fileSizeInMB} mb maximum`);
        return;
      }
      if (!Object.keys(SUPPORTED_FILE_TYPES).includes(fileExt)) {
        console.log(`Extension not supported ${fileExt}`);
        return;
      }
      if (fileType !== SUPPORTED_FILE_TYPES.tiff) {
        let image = document.createElement("img");
        image.src = URL.createObjectURL(curFile);
        setSearch({
          ...search,
          trademarkLogo: [{ img: image, file: curFile }],
        });
      } else {
        let image = document.createElement("img");
        image.src = TIFF_ICON;
        image.className = "tiff-icon";
        setSearch({
          ...search,
          trademarkLogo: [{ img: image, file: curFile }],
        });
      }
      setUploaded(true);
      setTouched({ ...touched, trademarkLogo: false });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    // remove tm Logo state values if url
    // was not persisted from the logo upload.
    if (isEmpty(search.trademarkLogo?.url)) {
      setSearch({ ...search, trademarkLogo: [] });
      setTouched({ ...touched, trademarkLogo: false });
    } else {
      setUploaded(true);
      setTouched({ ...touched, trademarkLogo: false });
    }
  }, []);

  const removeLogo = () => {
    setUploaded(false);
    setSearch({ ...search, trademarkLogo: [] });
  };

  return (
    <div className="prescreening-logo">
      <Row>
        <Col lg={{ offset: 3, span: 6 }}>
          <JurisdictionInput contentful={contentful} />
        </Col>
      </Row>
      <Row className="prescreening-logo-upload text-center">
        {!uploaded && (
          <Col md={12}>
            <p>Upload the logo you wish to trademark below</p>
          </Col>
        )}
        <Col>
          {!isEmpty(search.trademarkLogo[0]) ? (
            <div className="d-flex flex-column align-items-center">
              <img
                src={
                  search.trademarkLogo[0]?.img?.src
                    ? search.trademarkLogo[0]?.img?.src
                    : search.trademarkLogo?.url?.includes(".tif")
                    ? TIFF_ICON
                    : search.trademarkLogo?.url
                }
              />
              <div className="d-flex">
                <Button variant="link orange" onClick={() => removeLogo()}>
                  Click here to change your logo upload.
                </Button>
              </div>
            </div>
          ) : (
            <Form.Group className="h-100 prescreening-logo-file">
              <Form.Control
                id="img-file"
                type="file"
                className="h-100"
                accept="image/png, image/jpeg, image/tiff, image/bmp, image/gif"
                onChange={(e) => upload(e)}
                disabled={uploaded}
                onDrop={(e) => {
                  e.target.tagName == "INPUT" &&
                    document
                      .getElementById("upload-icon")
                      .classList.remove("hovered");
                }}
                onDragEnter={(e) => {
                  e.target.tagName == "INPUT" &&
                    document
                      .getElementById("upload-icon")
                      .classList.add("hovered");
                }}
                onDragLeave={(e) => {
                  e.target.tagName == "INPUT" &&
                    document
                      .getElementById("upload-icon")
                      .classList.remove("hovered");
                }}
              />
              <Form.Label className="h-100 d-flex text-center flex-column justify-content-center">
                <img
                  className="align-self-center my-3"
                  id="upload-icon"
                  aria-hidden="true"
                  src={uploadIcon}
                  height={160}
                  width={160}
                />
                {contentful.inputs.slice(0, 2).map((v, i) => (
                  <p key={i}>{v}</p>
                ))}
              </Form.Label>
            </Form.Group>
          )}
        </Col>
      </Row>
      <Row>
        <Col lg={{ offset: 3, span: 6 }}>
          <Form.Group>
            <Form.Label>{contentful.inputs[2].label}</Form.Label>
            <Typeahead
              contentful={{
                placeholder: contentful.inputs[2].placeholder,
              }}
              ariaLabel={contentful.inputs[2].placeholder}
              search={search}
              setSearch={setSearch}
              touched={touched}
              setTouched={setTouched}
            />
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

export default Logo;

import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

const CustomProgressBar = ({ animate, labels, activeStep, title }) => {
  const [animateStep, setAnimateStep] = useState(0);
  const step = activeStep || 0;
  useEffect(() => {
    if (animate) {
      const intervalId = setInterval(() => {
        setAnimateStep((animateStep + 1) % 5);
      }, 1500);

      return () => clearInterval(intervalId);
    }
  });

  return (
    <Container className="pb-3 searching d-lg-flex align-items-center justify-content-center">
      <Row className="text-center ">
        <Col>

          {title && (
            <p className="py-3">
              <h4>{title}</h4>
            </p>
          )}
          <div className="progress relative">
            <div
              className={`progress-bar ${
                animate
                  ? "indeterminate"
                  : title === "Secure a Trademark"
                  ? `active-step-${activeStep}`
                  : `prelim-step-${activeStep}`
              }`}
            ></div>
          </div>
          <div className="d-none d-lg-flex indicators">
            {labels.map((label, index) => {
              return (
                <div key={index}>
                  <span class="text-red font-weight-bold">|</span>
                  <h4 className="index text-red ">{`${index + 1}`}</h4>
                  <p className="label px-2">{label}</p>
                </div>
              );
            })}
          </div>

          <div className="d-lg-none indicators">
            <div>
              <span>|</span>

              {animate ? (
                <p className="label px-lg-2">{labels[animateStep]}</p>
              ) : (
                <>
                  <p className="index">{`${activeStep}`}</p>
                  <p className="label px-lg-2">{labels[step - 1]}</p>
                </>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CustomProgressBar;

import React, { useEffect, useContext } from "react";
import { TrademarkSearchContext } from "../context/TrademarkSearchContextProvider";
import Loading from "./Loading";
import TrademarkResults from "./TrademarkResults";
import { useParams } from "react-router-dom";

const contentful = {
  prescreenResults: {
    result: {
      fail: "Uh-oh",
      pass: "Low Risk",
    },
    subtitle: "Trademark Check",
    failModal: {
      desc1: "High-risk conflicts were found with existing trademarks.",
      desc2: {
        ca: "There is a chance your trademark will not pass CIPO review due to these conflicts",
        us: "There is a chance your trademark will not pass USPTO review due to these conflicts",
      },
      title: "Are you sure?",
      subtitle: "Some high-risk conflicts",
    },
    resultDesc: {
      fail: "trademark is not available for you to own.",
      pass: {
        logo: "has a good chance of success. Our AI has run all the checks, and it looks like your logo will register.",
        name: "trademark is available for you to own.",
        tagline: "trademark is available for you to own.",
      },
    },
    failMessage:
      "We found high risk conflicts with existing trademarks. Due to the legal risks we have identified, we do not recommend you use this trademark.",
    resultDesc2: {
      fail: "We found high risk conflicts with existing trademarks. Due to the legal risks we have identified, we do not recommend you use this trademark.",
      pass: {
        ca: "We found no conflicts that would likely prevent you from registering your trademark with the CIPO or that would put you at risk for being sued for infringement.",
        us: "We found no conflicts that would likely prevent you from registering your trademark with the USPTO or that would put you at risk for being sued for infringement.",
      },
    },
    callToAction: {
      lowRiskMessage:
        "Secure it before it's gone. Built by lawyers, Haloo’s self-service tools make trademarking easy and affordable for everyone.",
      highRiskMessage:
        "You should have more than one option for your trademark in case the first one you want is already taken. We recommend running another search.",
      lowRiskbuttonText: ["Buy now"],
      highRiskbuttonText: ["Run another search", "Buy now"],
      signupMessage: "Sign up for free to claim our partner discount $99 / $149",
      signupText: ['Sign up for free']
    },
  },
  socialDomainResults: {
    title: "Your Search Results",
    subtitle:
      "A domain and social handles are a great start! But, if you want to legally own your brand name, you should make sure it's available to trademark, too.",
    available: "available",
    socialList: ["facebook", "youtube", "instagram", "twitter"],
    recommended: "Expert Recommendation",
    socialTitle: "Domain Search Results",
    unavailable: "unavailable",
    trademarkTitle: "Trademark Search Results",
  },
};

const Results = () => {
  const { queryid } = useParams();
  const { setQuery, results } = useContext(TrademarkSearchContext);
  useEffect(() => {
    setQuery(queryid);
  }, [queryid]);

  return (
    <>
      {results === undefined || results === null ? (
        <Loading />
      ) : (
        <div className="prescreening-preview-results">
          <div className="prescreening">
            <TrademarkResults contentful={contentful}  />
          </div>
        </div>
      )}
    </>
  );
};

export default Results;

import React, { useContext, useState } from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import { TrademarkSearchContext } from "../context/TrademarkSearchContextProvider";
import check from "../assets/icons/check.svg";
import cross from "../assets/icons/cross.svg";
import SocialCard from "./SocialDomainResults";
import Haloo from "../assets/icons/powered-by-haloo.svg";
import Shoplazza from "../assets/icons/shoplazza.svg";
import HalooModal from "./HalooModal";
import { Link } from "react-router-dom";
import { OVERALLRISKSCORES } from "../constants";
import Cookies from 'universal-cookie';
import ShoplazzaFooter from './ShoplazzaFooter';

const PrescreenTrademarkResults = ({ contentful }) => {
  const { results } = useContext(TrademarkSearchContext);
  const cookies = new Cookies();
  const [showModal, setShowModal] = useState(false);

  const {
    prescreenResults: {
      subtitle,
      result,
      resultDesc,
      resultDesc2,
      callToAction,
    },
  } = contentful;

  console.log(results)
  const { trademark, overallRisk, trademarkId, uuid, country } = results;
  
  const overallRiskLowerCase =
    OVERALLRISKSCORES[`${overallRisk.toLowerCase()}`];
  const cta = (sectionId) => {
    return (
      <Container fluid className={sectionId == 1? "bg-blue-light py-8" : sectionId == 0 ? "py-8" : "bg-orange py-8"}>
        <Row className="justify-content-md-center text-center pt-5 pb-5">
            <Col md={12} className="pt-1">
              <Image height="26" src={Shoplazza} alt="Powered by Shoplazza | Haloo" className="align-middle"/>
              <span style={{paddingLeft: "12px"}}>|</span>
              <Image height="43" src={Haloo} alt="Powered by Shoplazza | Haloo" className="align-middle"/>
            </Col>
        </Row>
        <Row className="justify-content-center pb-5">
          <Col md={7}>
            <h4 className="text-center">
              {
               overallRiskLowerCase === "high" 
                ? <>
                   `${callToAction.highRiskMessage}`
                  </>
                : `${callToAction.lowRiskMessage}`
             }
            </h4>
          </Col>
        </Row>
        <Row className="justify-content-center">
          { cookies.get("is_login") && overallRiskLowerCase === "high" ? 
            <> 
              <Col xs={12} md={12} className="text-center">
                <Link className="" to="/get-started">
                  <Button
                    variant="primary"
                    className="w-25"
                    onClick={() => setShowModal(true)}
                  >
                    {callToAction.highRiskbuttonText[0]}
                  </Button>
                </Link>
              </Col>
           </>
          :   !cookies.get("is_login") ?  (
            <>
            
               <Col xs={12} md={12} className="pt-3 pt-md-0 text-center">
                <p className="pt-2 pb-3">Sign up for free to claim our partner discount $99 / <span className="text-decoration-line-through"><small>$149</small></span></p>
              </Col>
             <Col xs={11} md={4} className="text-center">
             <a
               href={`/auth/api?queryid=${uuid}`}
               className="text-white font-sanserif no-underline"
               target="_blank"
               rel="noreferrer"
             >
               <Button
                 variant="primary"
                 className="w-100 d-flex justify-content-center align-items-center"
               >
                 {callToAction.signupText[0]}
               </Button>
             </a>
           </Col>
           <Col xs={12} md={12} className="pt-5 pt-md-0 text-center">
             <p className="pt-4">
                {
                   overallRiskLowerCase !== "high" 
                ? <>
                    Have another idea in mind? Run another search <Link className="" to="/get-started"><b>here</b></Link>.
                  </>
                :   <>If you are already Shoplazza merchant, sign in your account to claim <b>your discount</b></>
                 }
            </p>
              </Col>
            </>
          ) : (
            <>
               <Col xs={12} md={12} className="pt-3 pt-md-0 text-center">
                <p className="pt-5 pb-3">Buy with our partner discount $99 / <span className="text-decoration-line-through">$149</span></p>
              </Col>
            <Col xs={11} md={4} className="text-center">
              <a
                href={`${process.env.REACT_APP_HALOO_URL}/${
                  country.toLowerCase() === "ca" ? "en-ca/" : ""
                }get-started?queryId=${trademarkId}`} 
                className="text-white font-sanserif no-underline"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  variant="primary"
                  className="w-100 d-flex justify-content-center align-items-center"
                >
                  {callToAction.lowRiskbuttonText[0]}
                </Button>
              </a>
            </Col>
            </>
          )}
        </Row>
      
      </Container>
    );
  };

  return (
    <>
      <HalooModal
        onNext
        showModal={showModal}
        setShowModal={setShowModal}
        type=""
        trademarkId={trademarkId}
        content={contentful.prescreenResults.failModal}
        jurisdiction={country.toLowerCase()}
      />
      <Container fluid className="bg-grey-light py-8">
        <Container fluid 
        className={`${
          overallRiskLowerCase === "high"
            ? "bg-orange-light text-center"
            : "bg-orange-light text-center"
        }`} >
          <>
            <p>{subtitle}</p>
            <img
              className="p-3"
              alt=""
              src={overallRiskLowerCase === "high" ? cross : check}
            />
          </>

          <h1 className="mb-5 text-red">
            {result[overallRiskLowerCase === "high" ? "fail" : "pass"]}
          </h1>
          <Row>
            <Col lg={{ offset: 3, span: 6 }}>
              <p className=" pb-3 text-red">
                It looks like your{" "}
                {results.type === "logo" ? (
                  "logo"
                ) : (
                  <u>
                    <strong>{trademark?.trademark?.toUpperCase()}</strong>
                  </u>
                )}{" "}
                {overallRiskLowerCase === "high"
                  ? resultDesc["fail"]
                  : resultDesc["pass"][results.type]}
              </p>
              <p  className="text-red">
                {overallRiskLowerCase === "high"
                  ? `${resultDesc2.fail}`
                  : `${resultDesc2.pass[`${country.toLowerCase()}`]}`}
              </p>
            </Col>
          </Row>
          <Row></Row>
        </Container>
      </Container>
      {results.type !== "logo" && cta(1)}
      {results.type === "logo" && (
        <Container className="text-center py-8 pb-5" >
          <img src={results.logo.imagePath} alt="your logo" height="100px" />
        </Container>
      ) }
       {results.type === "logo" && cta(0)}
      {results.type !== "logo" && <SocialCard results={results}/> }
      {results.type !== "logo" && cta(2)}
      <Container fluid className="bg-grey-light py-8">
        <Row>
            <Col>
            <ShoplazzaFooter />
            </Col>
        </Row>
      </Container>
    </>
  );
};

export default PrescreenTrademarkResults;

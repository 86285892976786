import React, { useContext } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { TrademarkSearchContext } from "../context/TrademarkSearchContextProvider";

const JurisdictionInput = ({ contentful }) => {
  const { search, setSearch } = useContext(TrademarkSearchContext);

  const content = [
    {
      name: "us",
      label: "USA",
    },
    {
      name: "ca",
      label: "Canada",
    },
  ];
  return (
    <Form.Group
      controlId="jurisdiction"
      className="jurisdiction"
      onChange={(e) => setSearch({ ...search, country: e.target.value })}
    >
      <Row>
        <Form.Label>Jurisdiction</Form.Label>
        {content.map((option, index) => {
          return (
            <Col md={6} key={`${index}-${option.name}`}>
              <div
                className={`form-control input ${
                  search.country === option.name ? "checked" : ""
                }`}
              >
                <Form.Check
                  defaultChecked={search.country === option.name}
                  id={option.name}
                  value={option.name}
                  type="radio"
                  name="jurisdiction"
                  label={option.label}
                />
              </div>
            </Col>
          );
        })}
      </Row>
    </Form.Group>
  );
};

export default JurisdictionInput;

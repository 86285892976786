import PrescreenView from "./views/PrescreenView";
import "./styles/main.scss";
import PartnerIntegrationView from "./views/PartnerIntegrationView";
import Results from "./components/PrescreenResults";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import TrademarkSearchContextProvider from "./context/TrademarkSearchContextProvider";
import React from "react";

function App() {
  return (
    <div className="haloo">
      <Routes>
        <Route path="/" element={<PartnerIntegrationView />} />
        <Route exact path="/get-started" element={<PrescreenView />} />
        <Route path="/results/:queryid" element={<Results />} />
      </Routes>
    </div>
  );
}

function PartnerPrescreen() {
  return (
    <BrowserRouter>
      <TrademarkSearchContextProvider>
        <App />
      </TrademarkSearchContextProvider>
    </BrowserRouter>
  );
}

export default PartnerPrescreen;

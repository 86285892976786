import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { isEmpty, trim } from "lodash";
import axios from "../axios";

const Typeahead = ({
  handleFocus,
  ariaLabel,
  type,
  touched,
  setTouched,
  contentful: { placeholder, errorTooltip },
  search,
  setSearch,
}) => {
  const [selected, setSelected] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [text, setText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);

  // preload from local storage
  useEffect(() => {
    setSelected(search.businessCategories);
  }, [search.businessCategories]);

  useEffect(() => {
    const sanitizedText = text.replace(/'| \//, "");
    const body = {
      country: search.country,
      input: sanitizedText,
    };
    if (!isEmpty(trim(text))) {
      axios
        .post("/industryactivity", body)
        .then(function ({ data }) {
          setSuggestions(data.results);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [text]);

  const handleTextChange = (e) => {
    const value = e.target.value;
    if (value.length > 0) {
      setSuggestions(
        suggestions
          ?.sort()
          .filter((v) => v.toLowerCase().includes(value.toLowerCase()))
      );
    }
    setText(value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown" && currentIndex < suggestions.length - 1) {
      let el = document.getElementById(`${currentIndex + 1}`);
      let next = 0;
      let i = 1;
      while (
        document
          .getElementById(`${currentIndex + i}`)
          ?.className.includes("disabled")
      ) {
        next++;
        i++;
      }
      setCurrentIndex(currentIndex + 1 + next);
      el?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    } else if (e.key === "ArrowUp" && currentIndex > 0) {
      let el = document.getElementById(` -${currentIndex - 1}`);
      let prev = 0;
      let i = 1;
      while (
        document
          .getElementById(` -${currentIndex - i}`)
          ?.className.includes("disabled")
      ) {
        prev++;
        i++;
      }
      setCurrentIndex(currentIndex - 1 - prev);
      el?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    } else if (e.key === "Enter") {
      selectSuggestion(suggestions[currentIndex]);
      setShowSuggestions(false);
      setCurrentIndex(-1);
      e.target.blur();
    } else if (e.key === "Escape") {
      setShowSuggestions(false);
      setCurrentIndex(-1);
      e.target.blur();
    }
  };

  const selectSuggestion = (value) => {
    let temp = selected;
    if (!temp.includes(value) && value !== undefined) {
      temp?.push(value);
      setSelected(temp);
      setTouched({ ...touched, businessCategories: false });
      setSearch({ ...search, businessCategories: temp });
    }
    setText("");
    setSuggestions([]);
  };

  const getHighlightedText = (text, highlight) => {
    const sanitizedHighlight = highlight.replace(/\\/gi, "");

    const parts = text?.split(
      new RegExp(`(${sanitizedHighlight ? sanitizedHighlight : ""})`, "gi")
    );
    return parts?.map((part, i) => (
      <span
        key={i}
        className={
          part.toLowerCase() === highlight.toLowerCase()
            ? "typeahead-highlight"
            : ""
        }
      >
        {part}
      </span>
    ));
  };
  const renderSuggestions = () => {
    if (!showSuggestions) return null;
    return (
      <ul>
        {!isEmpty(trim(text)) &&
          suggestions?.map((suggestion, i) => (
            <li
              id={`${i}`}
              key={i}
              className={
                selected.includes(suggestion)
                  ? "typeahead-option-disabled"
                  : i === currentIndex
                  ? "typeahead-option typeahead-option-active"
                  : "typeahead-option"
              }
              onMouseDown={() => {
                setShowSuggestions(false);
                if (!selected.includes(suggestion)) {
                  selectSuggestion(suggestion);
                }
              }}
            >
              {getHighlightedText(suggestion, text)}
            </li>
          ))}
      </ul>
    );
  };

  const updateSelection = (value) => {
    let temp = selected?.filter((v) => v !== value);
    if (temp.length === 0) {
      setTouched({ ...touched, businessCategories: true });
    }
    setSelected(temp);
    setSearch({ ...search, businessCategories: temp });
  };

  const node = useRef();

  const handleClickOutside = (e) => {
    if (
      (node.current && node.current.contains(e.target)) ||
      e.target.nodeName == "UL"
    ) {
      return;
    }
    setShowSuggestions(false);
  };

  useEffect(() => {
    if (setShowSuggestions) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowSuggestions]);

  return (
    <div className="typeahead">
      <span
        className={`typeahead-tooltip ${
          touched.businessCategories ? "error" : ""
        }`}
      >
        {errorTooltip}
      </span>
      <div>
        <input
          id="industries"
          aria-label={ariaLabel}
          className={touched.businessCategories ? "input input-error" : "input"}
          onFocus={() => {
            setShowSuggestions(true);
            handleFocus && handleFocus(true);
          }}
          onChange={(e) => handleTextChange(e)}
          onBlur={() => {
            selected.length === 0 &&
              setTouched({ ...touched, businessCategories: true });
          }}
          ref={node}
          onKeyDown={(e) => handleKeyDown(e)}
          placeholder={placeholder}
          value={text}
          type="text"
        />
      </div>
      {renderSuggestions()}
      {selected.map((industry, index) => (
        <div key={`${index}-${industry}`} className="typeahead-selection">
          <div
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                updateSelection(industry);
              }
            }}
            className="typeahead-selection-text"
          >
            <p>{industry}</p>
            <button
              aria-label="remove selection"
              onClick={() => updateSelection(industry)}
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Typeahead;

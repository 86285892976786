import React from "react";
import { Col, Button, Row } from "react-bootstrap";

const Stepper = ({
  nextText,
  showNext = true,
  showPrev = true,
  onNext,
  onPrev,
  disabled,
}) => {
  return (
    <>
      <Row className="justify-content-center">
        <Col className="text-center text-md-center" sm={12} md={3} lg={3}>
          {showNext && (
            <Button
              disabled={disabled}
              className="w-100"
              variant="outline"
              onClick={onNext}
            >
              {nextText}
            </Button>
          )}
          {showPrev && (
            <Button variant="link" onClick={onPrev}>
              <p className="">Back</p>
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Stepper;

import React, { useContext, useState, useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { TrademarkSearchContext } from "../context/TrademarkSearchContextProvider";
import ProgressBar from "./CustomProgressBar";
import Stepper from "./Stepper";
import { isEmpty, camelCase } from "lodash";
import Name from "./Name";
import Tagline from "./Tagline";
import Logo from "./Logo";
import Overview from "./Overview";
import { CSSTransition } from "react-transition-group";
import axios from "../axios";
import { useNavigate } from "react-router-dom";
import Loading from "./Loading";
import { pick, trim } from "lodash";

const TrademarkSelection = ({ contentful }) => {
  let navigate = useNavigate();
  const {
    search,
    setSearch,
    setView,
    validationErrors,
    validated,
    touched,
    setTouched,
    setSearchError,
    setIsSearchRunning,
    isSearchRunning,
  } = useContext(TrademarkSearchContext);

  const {
    progressBar: { prescreen, prescreenTitle },
    trademarkType: { title, subtitle, cards, logoType, nameType, taglineType },
  } = contentful;

  const validateFields = () => {
    const tempObj = {
      ...pick(search, ["fullName", "email", "businessCategories", "type"]),
    };

    if (search.type === "logo") {
      tempObj["trademarkLogo"] = search.trademarkLogo;
    } else if (search.type !== "logo") {
      tempObj["trademark"] = search.trademark;
    }
    Object.entries(tempObj).forEach((field) => {
      if (isEmpty(trim(field[1]))) {
        touched[field[0]] = true;
        setTouched({
          ...touched,
          [field[0]]: true,
        });
      }
    });

    if (validated) {
      runSearch();
    }
  };

  const runSearch = async () => {
    setIsSearchRunning(true);
    console.log("are you running search")
    setSearchError(null);
    try {
      let logoUrl = null;
      if (
        search.type === "logo" &&
        !isEmpty(search.trademarkLogo) &&
        isEmpty(search.trademarkLogo.url)
      ) {
        const formData = new FormData();
        formData.append("logo", search.trademarkLogo[0]?.file);
        const response = await axios.post(`/file/upload`, formData);
        logoUrl = response.data;
        // store returned url in state
        setSearch({
          ...search,
          trademarkLogo: { ...search.trademarkLogo, url: logoUrl },
        });
      }

      const { data } = await axios.post(`/`, {
        email: search.email,
        trademark: search.trademark,
        country: search.country,  
        business_categories: search.businessCategories,
        type: search.type,
        fullname: search.fullName,
        alternate_spellings: search.alternateSpellings,
        trademark_logo: logoUrl ? logoUrl : search.trademarkLogo.url,
      });
      setIsSearchRunning(false);
      navigate(`/results/${data.queryId}`);
    } catch (error) {
      setIsSearchRunning(false);
      setSearchError("clientError");
    }
  };

  useEffect(() => {
    Object.keys(validationErrors.all()).forEach((k) => {
      setTouched({ ...touched, [camelCase(k)]: true });
    });
  }, [validationErrors]);

  // TRADEMARK SELECTION
  const [toggle, setToggle] = useState("overview");

  return (
    <>
      {isSearchRunning ? (
        <Loading />
      ) : (
        <>
          <Container fluid className="pt-md-5 pt-5 bg-grey-light">
            <Row className="text-center pb-3">
              <Col md={12}>
                <h1 className="text-red">
                  {toggle === "tagline"
                    ? taglineType.title
                    : toggle === "name"
                    ? nameType.title
                    : toggle === "logo"
                    ? logoType.title
                    : title}
                </h1>
                <ProgressBar
                  labels={prescreen}
                  activeStep={toggle === "overview" ? 2 : 3}
                  title={prescreenTitle}
                />
              </Col>
            </Row>
          </Container>
          <Container fluid id="trademark-selection-container" className="py-8">
              <Row className="text-center">
                <Col md={{offset:3, span:6}}>
                {toggle === "overview" ? (
                  <h4 className="py-3">{subtitle}</h4>
                ) : toggle !== "logo" ? (
                  <h4 className="py-3">{nameType.subtitle}</h4>
                ) : null}
                </Col>
              </Row>

            <Container className="py-3">
              <CSSTransition
                in={toggle === "overview"}
                timeout={200}
                classNames="grow"
              >
                <div>
                  {toggle === "overview" && (
                    <Overview contentful={cards} setToggle={setToggle} />
                  )}
                </div>
              </CSSTransition>

              <CSSTransition
                in={toggle === "name"}
                timeout={200}
                classNames="grow"
              >
                <div>
                  {toggle === "name" && (
                    <Name
                      contentful={nameType}
                      touched={touched}
                      setTouched={setTouched}
                      validationErrors={validationErrors}
                    />
                  )}
                </div>
              </CSSTransition>
              <CSSTransition
                in={toggle === "logo"}
                timeout={200}
                classNames="grow"
              >
                <div>{toggle === "logo" && <Logo contentful={logoType} />}</div>
              </CSSTransition>

              <CSSTransition
                in={toggle === "tagline"}
                timeout={200}
                classNames="grow"
              >
                <div>
                  {toggle === "tagline" && (
                    <Tagline
                      contentful={taglineType}
                      touched={touched}
                      setTouched={setTouched}
                    />
                  )}
                </div>
              </CSSTransition>
              {touched.trademarkLogo &&
                search.type === "logo" &&
                toggle === "logo" && (
                  <p className="error text-center">
                    You must upload a logo to begin the search
                  </p>
                )}
              <div className="mt-5">
                <Stepper
                  nextText={"Run search"}
                  showPrev
                  showNext={toggle !== "overview"}
                  onNext={() => {
                    validateFields();
                  }}
                  onPrev={() =>
                    toggle === "overview"
                      ? setView("info")
                      : setToggle("overview")
                  }
                />
              </div>
            </Container>
          </Container>
        </>
      )}
    </>
  );
};

export default TrademarkSelection;

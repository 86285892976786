import React, { useState } from "react";
import { Container, Row, Col, Card, Button, Image } from "react-bootstrap";
import ytLogo from "../assets/icons/youtube-icon.svg";
import fbLogo from "../assets/icons/facebook-icon.svg";
import igLogo from "../assets/icons/instagram-icon.svg";
import twLogo from "../assets/icons/twitter-icon.svg";
import check from "../assets/icons/available-check.svg";
import cross from "../assets/icons/black-cross.svg";
import caret from "../assets/icons/caret.svg";
import { isDomainAvailable } from "../utils";

const socialMediaOrder = [
  {
    socialMedia: "Facebook",
    src: fbLogo,
  },
  {
    socialMedia: "Instagram",
    src: igLogo,
  },
  {
    socialMedia: "Twitter",
    src: twLogo,
  },
  {
    socialMedia: "YouTube",
    src: ytLogo,
  },
];

/**
 * Builds the JSX for the domain results
 * @param {Object} domain domain record
 * @param {Number} i index
 * @param {Boolean} includeExpert boolean
 * @returns JSX
 */
const domainRows = (domain, i, includeExpert = false) => {
  return (
    <Row className="tr text-md-start" key={`google-${i}`}>
      <Col
        xs={12}
        sm={6}
        lg={8}
        className={`${
          i === 0 && includeExpert && isDomainAvailable(domain.availability)
            ? "expert"
            : ""
        } pe-0`}
      >
        <p
          className={
            isDomainAvailable(domain.availability) ? "" : "card-unavailable"
          }
        >
          {domain.domainName.split(".")[0]}
          <strong>.{domain.domainName.split(".")[1]}</strong>
          {i === 0 &&
            includeExpert &&
            isDomainAvailable(domain.availability) && (
              <mark className="mx-0 mx-md-5">Expert Recommendation</mark>
            )}
        </p>
      </Col>

      <Col xs={12} sm={4} lg={2}>
        {domain?.yearlyPrice && (
          <p className=" text-center text-md-start">
            ${domain?.yearlyPrice?.units}
            /year
          </p>
        )}
      </Col>
      <Col xs={12} sm={2} lg={2} className="align-self-center">
        <div className="mt-2 mt-md-0">
          <img
            src={isDomainAvailable(domain?.availability) ? check : cross}
            alt={
              isDomainAvailable(domain?.availability)
                ? "available"
                : "unavailable"
            }
          />
        </div>
      </Col>
    </Row>
  );
};

const SocialDomainResults = ({ results }) => {
  const [expand, setExpand] = useState({
    domain: false,
    social: false,
  });

  // Massage the media results to categorize the media types
  const massagedSocialList = (mediaResults) => {
    const socialList = {};
    mediaResults.forEach((media) => {
      if (socialList[media.website]) {
        socialList[media.website].push(media);
      } else {
        socialList[media.website] = [media];
      }
    });
    return socialList;
  };

  /**
   * Creates the list for the socialMediaType e.g facebook
   * When collapsed, we display max of 2, and when expanded we display all
   * @param {Object} massagedMediaList
   * @param {String} socialMediaType
   * @returns jsx
   */
  const socialMediaRows = (massagedMediaList, socialMediaType) => {
    return (
      <>
        {massagedMediaList[socialMediaType.toLowerCase()]
          .slice(0, 2)
          .map((media, i) => {
            return (
              <Row className="tr" key={i}>
                <Col sm={9} className="text-sm-start align-self-center">
                  <p>
                    {`${socialMediaType.toLowerCase()}.com/`}
                    <strong>{media.name.toLowerCase()}</strong>
                  </p>
                </Col>
                <Col sm={3} className="align-self-center text-center">
                  <div className="mt-3 mt-md-0">
                    <img
                      src={media.status ? check : cross}
                      alt={media.status ? "available" : "unavailable"}
                    />
                  </div>
                </Col>
              </Row>
            );
          })}
        {expand.social &&
          massagedMediaList[socialMediaType.toLowerCase()]?.length > 2 && (
            <>
              {massagedMediaList[socialMediaType.toLowerCase()]
                .slice(2, massagedMediaList[socialMediaType]?.length)
                .map((media, i) => {
                  return (
                    <Row className="tr" key={i}>
                      <Col sm={9} className="text-sm-start align-self-center">
                        <p>
                          {`${socialMediaType.toLowerCase()}.com/`}
                          <strong>{media.name.toLowerCase()}</strong>
                        </p>
                      </Col>
                      <Col sm={3} className="align-self-center text-center">
                        <div className="mt-3 mt-md-0">
                          <img
                            src={media.status ? check : cross}
                            alt={media.status ? "available" : "unavailable"}
                          />
                        </div>
                      </Col>
                    </Row>
                  );
                })}
            </>
          )}
      </>
    );
  };

  return (
    <Container className={`${!results.domain.result ? "d-none" : ""} py-8`}>
      <Row className="justify-content-center g-0">
        <Col xs={12} md={10}>
          <h2 className="text-center font-sanserif pb-5">
            Domains and Social Handles
          </h2>
        </Col>
      </Row>
      <Row className="g-0">
        <Col>
          <Card className="p-5">
            <Card.Body>
              {/* DOMAINS */}
              <h4 className="card-title">DOMAINS</h4>
              <Row>
                <Col md={12}>
                  <Row className="thead">
                    <Col
                      sm={4}
                      lg={{ span: 2, offset: 8 }}
                      className="d-none d-lg-block"
                    >
                      <h5>PRICE</h5>
                    </Col>
                    <Col sm={2} lg={2} className="d-none d-lg-block">
                      <h5>AVAILABILITY</h5>
                    </Col>
                  </Row>
                  {results?.domain.result
                    ?.slice(0, 10)
                    .map((domain, i) => domainRows(domain, i, true))}
                  {expand.domain &&
                    results?.domain.result
                      ?.slice(10, results?.domain.legnth)
                      .map((domain, i) => domainRows(domain, i))}
                  {results.domain?.result &&
                    results.domain?.result.length > 10 && (
                      <Col md={12} className="text-center">
                        <Button
                          variant="toggle"
                          aria-expanded={expand.domain}
                          onClick={() =>
                            setExpand({ ...expand, domain: !expand.domain })
                          }
                          className="btn btn-primary"
                        >
                          {expand.domain ? "Hide" : "Show more"}
                    
                        </Button>
                      </Col>
                    )}
                </Col>
              </Row>
              {/* SOCIAL MEDIA */}
              <Row className="text-center text-md-start pt-5">
                <p className="card-title text-start text-uppercase">Social Media</p>
                {socialMediaOrder.map((sm, index) => {
                  return (
                    <Col key={index} className="mt-5" xs={12} lg={6}>
                      <img height={75} src={sm.src} alt={sm.socialMedia} />
                      <Row className="thead mt-2">
                        <Col md={9}>
                          <p>
                            <small>{sm.socialMedia}</small>
                          </p>
                        </Col>
                        <Col className="d-none d-md-block text-center" md={3}>
                          <p>
                            <small>Availability</small>
                          </p>
                        </Col>
                      </Row>
                      {socialMediaRows(
                        massagedSocialList(results.media.result),
                        sm.socialMedia
                      )}
                    </Col>
                  );
                })}

                {results.media?.result && results.media?.result?.length > 2 && (
                  <Col md={12} className="text-center pt-5">
                    <Button
                      variant="toggle"
                      aria-expanded={expand.social}
                      onClick={() =>
                        setExpand({ ...expand, social: !expand.social })
                      }
                      className="btn btn-primary "
                    >
                      {expand.social ? "Hide" : "Show more"}
              
                    </Button>
                  </Col>
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SocialDomainResults;
